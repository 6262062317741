


















































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { UsersList, BanApplyAccount, ResetPwd } from "@/request/manage";
import MainHeader from "@/components/main-header/main-header.vue";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private user: any = {}; // 当前登录用户信息
  private window: any = window;
  private users: any[] = [];
  private pageSetting: any = {};
  /**
   * @description 按照机构来查找
   */
  private searchInstitution(id: any) {
    this.filters["institution_id"] = id;
    this.search();
  }
  /**
   * @description 跳转用户编辑
   */
  private jumpCompose(user: any) {
    if (!this.getIsShowQuanxian("用户管理编辑")) {
      return;
    }
    if (user["状态"] === "禁用") {
      this.$message.warning("该账户已经禁用，不能编辑");
      return;
    }
    this.$store.commit("updateZhanghu", user);
    this.$router.push({
      path: "/main/manage/zhanghu/compose",
      query: {
        id: user._id,
      },
    });
  }
  /**
   * @description 跳转添加用户
   */
  private jumpAddUser() {
    this.$store.commit("updateZhanghu", {});
    this.$router.push({
      path: "/main/manage/zhanghu/compose",
    });
  }
  /**
   * @description 重置用户密码
   */
  private resetPwd(user: any) {
    if (!this.getIsShowQuanxian("用户管理重置密码")) {
      return;
    }
    this.$confirm("确定要重置该账号的密码吗？", "重置密码", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          params: {
            user_id: user._id,
          },
        };
        ResetPwd(this, params).then((data: any) => {
          this.$message.success("重置成功");
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  /**
   * @description 禁用或启用账号
   */
  private banApplyAccount(user: any) {
    if (!this.getIsShowQuanxian("用户管理禁用")) {
      return;
    }
    const action: any = user["is_active"] ? "禁用" : "启用";
    if (action === "禁用") {
      this.$confirm("禁用后该账户不能进入该网站，确定要禁用吗？", action, {
        customClass: "commonConfirm",
      })
        .then(() => {
          const params: any = {
            data: {
              user_id: user._id,
            },
          };
          BanApplyAccount(this, params).then((data: any) => {
            this.$message.success(action + "成功");
            this.getList();
          });
        })
        .catch((e) => {
          console.log("取消了");
        });
    } else {
      const params: any = {
        data: {
          user_id: user._id,
        },
      };
      BanApplyAccount(this, params).then((data: any) => {
        this.$message.success(action + "成功");
        this.getList();
      });
    }
  }
  /**
   * @description 自动计算角色
   */
  private calJuese(juese: any) {
    let jigouText = "";
    juese["组织机构"].forEach((jigou: any) => {
      jigouText += jigou["名称"] + "-";
    });
    jigouText = jigouText.substring(0, jigouText.length - 1);
    return juese["role"]["名称"] + "(" + jigouText + ")";
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    const params: any = {
      params: {
        search: this.filters.search,
        order_field: "创建时间",
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
      },
    };
    localStorage.setItem("yonghuguanliliFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    UsersList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.users = data.users;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.users.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  mounted() {
    const d = localStorage.getItem("yonghuguanliliFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
  }
}
